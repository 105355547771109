<template>
    <treeselect class="custom-el-select-tree" :max-height="maxHeight ?? 300" :openDirection="openDirection ?? 'auto'" v-if="showSelect" v-model="model" :placeholder="selectPlaceHolder" :noResultsText="$t('messages.noResultFound')" :multiple="selectMultiple" :options="options" :normalizer="normalizer" :search-nested="searchNested" :disableBranchNodes="disableCheckBranchNodes"/>
</template>

<script>
import Treeselect from '@bosquig/vue3-treeselect'
import '@bosquig/vue3-treeselect/dist/vue3-treeselect.css'

export default {
    name: "ElSelectTree",
    props: ['value', 'multiple', 'data', 'disableBranchNodes', 'search', 'placeholder', 'openDirection', 'maxHeight'],
    components: { Treeselect },
    data() {
        return {
            selectMultiple: true,
            options: [],
            disableCheckBranchNodes: true,
            showSelect: true,
            searchNested: true,
            disableShowable: false,
            selectPlaceHolder: this.$t('common.chooseSelect'),
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.title,
                    children: node.children,
                }
            }
        }
    },
    created(){
        this.selectMultiple = this.multiple ?? true;
        this.loadOptions();
        this.disableCheckBranchNodes = this.disableBranchNodes ?? true;
        this.searchNested = this.search ?? true;
        if(this.placeholder) {
            this.selectPlaceHolder = this.placeholder;
        }
    },

    computed: {
        model: {
            get() {
                return this.value
            },
            set(val) {
                this.disableShowable = true;
                this.$emit('input', val)
            }
        },
    },
    methods: {
        loadOptions(){
            this.options = this.checkTreeData(this.cloneData(this.data ?? []));
        }
    },
    watch: {
        data: function(){
            this.loadOptions();
        },
        value: function(value, oldValue){
            if(this.disableShowable) {
                return;
            }

            this.showSelect = false;
            this.$nextTick(() => {
                this.showSelect = true;
                this.disableShowable = false;
            });
        }
    }
}
</script>

<style>
    .custom-el-select-tree {
        color: #606266;
    }
    .custom-el-select-tree .vue-treeselect__control{
        height: 40px;
    }
    .custom-el-select-tree .vue-treeselect__placeholder, .custom-el-select-tree  .vue-treeselect__single-value {
        line-height: 40px;
    }
    .custom-el-select-tree > * {
        line-height: normal;
        outline: none !important;
        box-shadow: none !important;
    }
    .custom-el-select-tree .vue-treeselect__multi-value-item{
        background: #f0f2f5;
        border-color: transparent;
        color: #909399;
        min-height: 24px;
        /*line-height: 24px;*/
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .custom-el-select-tree .vue-treeselect__value-remove {
        color: #909399 !important;
        border-radius: 50%;
        border: none !important;
        height: 16px;
        width: 16px;
        line-height: 16px;

        display: inline-block;
    }
    .custom-el-select-tree .vue-treeselect__multi-value-item:hover .vue-treeselect__value-remove, .custom-el-select-tree .vue-treeselect__value-remove:hover {
        color: #ffffff !important;
        background-color: #909399;
    }

    .custom-el-select-tree .vue-treeselect__x-container:hover{
        color: #616161 !important;
    }

    .custom-el-select-tree .vue-treeselect__control, .custom-el-select-tree .vue-treeselect__menu {
        border-color: #dcdfe6;
    }

    .custom-el-select-tree.vue-treeselect--focused .vue-treeselect__control, .custom-el-select-tree .vue-treeselect__menu {
        border-color: #409eff;
    }

    .is-error .custom-el-select-tree .vue-treeselect__control,  .is-error .custom-el-select-tree .vue-treeselect__menu {
        border-color: #f56c6c;
    }

    .custom-el-select-tree .vue-treeselect__control{
        border-radius: 4px;
    }
    .custom-el-select-tree .vue-treeselect__menu{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .custom-el-select-tree .vue-treeselect__list-item .vue-treeselect__option{
        height: 34px;
        line-height: 34px;
    }
    .custom-el-select-tree .vue-treeselect__list-item .vue-treeselect__option--highlight {
        background: #f5f7fa;
    }
    .custom-el-select-tree .vue-treeselect__list-item .vue-treeselect__option--selected {
        color: #409eff;
    }
    .custom-el-select-tree .vue-treeselect__list-item .vue-treeselect__option--selected:hover {
        background-color: #f5f7fa;
    }
    .custom-el-select-tree .vue-treeselect__input-container, .custom-el-select-tree .vue-treeselect__input{
        color: #606266;
        font-size: 13px;
    }
    .custom-el-select-tree.vue-treeselect--searchable:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) .vue-treeselect__value-container{
        cursor: pointer;
    }
</style>